<template src="./HcResult.html">
   
</template>

<script>

    export default {
        name: 'HcResult',

        components: {
        },

        created() {
        },

        data: () => ({
         
        }),

        methods: {
        }
    };
</script>

<style lang="scss" scoped>
</style>

